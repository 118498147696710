<template>
  <div class="container">
    <validation-observer ref="observer">
      <md-field title="绑定手机号码" class="margin">
        <v-input title="姓名" placeholder="请填写姓名" v-model="name" clearable rules="required" isTitleLatent></v-input>
        <v-input title="手机号码" placeholder="请填写手机号码" v-model="phone" clearable rules="required|phone" isTitleLatent></v-input>
        <v-input
          title="邀请码"
          placeholder="请填写邀请码"
          v-model="inviteCode"
          clearable
          rules="required|codeIsExsit"
          isTitleLatent
        ></v-input>
      </md-field>
    </validation-observer>
    <div class="margin">
      <md-button type="primary" @click="handleSend">发送验证码</md-button>
    </div>
    <md-captcha ref="captcha" v-model="visible" title="输入验证码" :maxlength="6" @submit="handleSumbit" @send="onSend">
      {{ content }}
    </md-captcha>
  </div>
</template>

<script>
import * as userApi from '@/api/user'

export default {
  data() {
    return {
      name: '',
      phone: '',
      code: '',
      codeKey: '',
      inviteCode: '',
      visible: false,
    }
  },
  computed: {
    content() {
      return `验证码已发送至 ${this.phone}`
    },
  },
  methods: {
    handleSend() {
      this.$refs.observer.validate().then(result => {
        if (!result) {
          return
        }
        this.visible = true
      })
    },
    onSend() {
      userApi.smsCode({ phone: this.phone }).then(res => {
        this.codeKey = res.data.key
      })
    },
    handleSumbit(val) {
      const params = {
        name: this.name,
        phone: this.phone,
        code_key: this.codeKey,
        code: val,
        invite_code: this.inviteCode,
      }
      userApi.upgrade(params).then(() => this.$router.push({ name: 'user' }))
    },
  },
}
</script>
